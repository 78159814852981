<template>
  <div id="pic-wrapper" ref="wrapper">
    <div v-show="bkImage !=''" :style="{background:'url('+bkImage+')'}" class="content" id="my-pic">
      <!--雨量数据-->
      <div class="rain">
        <RainGroup :liveData="siteItem"></RainGroup>
      </div>
      <!--赣江水位-->
      <div v-if="zjWaterX" :style="{left:zjWaterX+'px',top:zjWaterY+'px'}" class="zj-water">
        <LiveData :liveData="zjWater"></LiveData>
      </div>
      <!--赣江水位-->
      <div v-if="subX" :style="{left:subX+'px',top:subY+'px'}" class="subWater">
        <LiveData :liveData="subWater"></LiveData>
      </div>
    </div>
  </div>
</template>

<script>
import basepage from './common/basepage.js';
import LiveData from './components/liveItem.vue'
import RainGroup from './components/rainGroup'
export default {
  mixins: [basepage],
  components: {
    LiveData,
    RainGroup,
  },
  data() {
    return {
      bkImage: '',//背景图
      uid: 'SYS00181LXY',
      siteIndex: null,
      siteItem: {},
      option: {
        targetX: 1920,
        targetY: 1080,
        targetRatio: 16 / 9
      },
      zjWaterX: null,
      zjWaterY: null,
      subX: null,
      subY: null,
    }
  },
  computed: {
    zjWater() {
      let data = {
        title: this.siteItem.mainTitle,
        num: this.siteItem.mainWater,
        unit: 'm'
      }
      return data
    },
    subWater() {
      let data = {
        title: this.siteItem.subTitle,
        num: this.siteItem.subWater,
        unit: 'm'
      }
      return data
    },
  },

  methods: {
    on_siteDataReady(res) {
      if (res && res.sites.length > 0) {
        let siteArr = res.sites
        //未找到数据标识
        if (!this.siteIndex) {
          this.siteIndex = siteArr.findIndex(item => item.mainUid == this.uid)
        }
        //取出对应数据
        let siteData = siteArr[this.siteIndex]
        //第一次需要设置
        if (this.bkImage == '') {
          this.bkImage = 'https://iot.jjyip.com' + siteData.bkImage
          this.zjWaterX = siteData.mainX || 600
          this.zjWaterY = siteData.mainY || 200
          this.subX = siteData.subX || 1651
          this.subY = siteData.subY || 400
        }
        // console.log('222', res);

        this.siteItem = siteData
      }
    },
    // setScreen() {
    //   const screenWidth = window.screen.width;
    //   const screenHeight = window.screen.height;
    //   console.log(3331, screenWidth, screenHeight);
    //   this.targetX = screenWidth
    //   this.targetY = screenHeight
    //   this.targetRatio = parseInt(screenWidth) / parseInt(screenHeight)
    // },
    // resizeFunc: () => {
    //   this.triggerScale();
    // },
    // triggerScale() {
    //   console.log("执行了");
    //   // 设计稿尺寸
    //   let targetX = this.option.targetX;
    //   let targetY = this.option.targetY;
    //   let targetRatio = this.option.targetRatio;

    //   // 拿到当前设备的宽高
    //   let currentX = document.documentElement.clientWidth || document.body.clientWidth;
    //   let currentY = document.documentElement.clientHeight || document.body.clientHeight;

    //   let scaleRadio = void 0
    //   let currentRadio = currentX / currentY;
    //   // 根据设计稿尺寸和当前电脑尺寸比较
    //   if (currentRadio > targetRatio) {
    //     // 按高度缩放，居中展示
    //     scaleRadio = currentY / targetY;
    //     this.$refs.wrapper.style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio}) translateX(-50%);left:50%;`;
    //     // document.getElementById("my-pic-wrapper").
    //   } else {
    //     scaleRadio = currentX / targetX;
    //     this.$refs.wrapper.style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio});`;
    //     // document.getElementById("my-pic-wrapper").style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio});`;
    //   }
    // },
    // debounce(func, delay = 1000, immediate = false) {
    //   let timer = null
    //   //不能用箭头函数
    //   return function () {
    //     //在时间内重复调用的时候需要清空之前的定时任务
    //     if (timer) {
    //       clearTimeout(timer)
    //     }
    //     //适用于首次需要立刻执行的
    //     if (immediate && !timer) {
    //       func.apply(this, arguments)
    //     }
    //     timer = setTimeout(() => {
    //       func.apply(this, arguments)
    //     }, delay)
    //   }
    // },
    //获取数据
    getSiteData() {
      let json = {
        "cmd": "siteData",
      }
      this.send(json)
    }
  },
  created() {
    // this.setScreen()
  },
  mounted() {
    this.on('siteDataReady', (res) => {
      this.on_siteDataReady(res);
    })
    this.on('connected', (res) => {
      this.getSiteData(); //连接成功再发送cmd
    })
    // window.addEventListener("resize", this.debounce(this.triggerScale, 300, false));
    // this.triggerScale()
  },
  destroyed() {
    // window.removeEventListener("resize", this.triggerScale);
  },
}
</script>

<style lang="scss">
$h: 1080px;
$w: 1920px;
#pic-wrapper {
  position: relative;
  /* 设计稿 */
  width: 1920px;
  height: 1080px;
  // background: red;

  box-sizing: border-box;
  transform-origin: left top;
}
.content {
  // background: url("../../assets/pic_a.png");
  height: $h !important;
  width: $w !important;
  background-size: $w $h !important;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  position: absolute;

  .rain {
    position: absolute;
    // height: 50px;
    width: 200px;
    top: 75px;
    left: 321px;
  }

  .zj-water {
    position: absolute;
    // height: 50px;
    width: 200px;
    // top: 200px;
    // left: 600px;
  }
  .subWater {
    position: absolute;
    // height: 50px;
    width: 200px;
    // top: 400px;
    // left: 1651px;
  }
}
</style>