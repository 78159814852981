//+"192.168.1.3"
import { baseUrl } from './request/env'
const port = location.port
let url = "wss://" + document.domain + ":" + port + "/iot.yiChun" //iot.yiChun 宜春项目
if (port == 8080 || port == 80 || port == 8081 || port == 81) {
  //是在node.js环境下运行,连接IotPlatform.exe
  if (process.env.NODE_ENV === 'development') {
    url = "ws://" + baseUrl + "/iot.yiChun"
  } else {
    url = "ws://" + baseUrl + "/iot.yiChun"
  }
}
console.log("location.port=" + location.port);
console.log("yichun_url=" + url);
let gDumpCommand = true;
//let gDumpCommand = false;

var ws;
var tt;
var events = {};
var lockReconnect = false; //避免重复连接
let wsConnected = false;
let isPing = false;
let Base64 = require('js-base64').Base64
//此websocket为连接宜春大屏项目使用
var websocket = {
  connect() {
    console.log(333);
    if ("WebSocket" in window) {
      ws = new WebSocket(url);
    } else if ("MozWebSocket" in window) {
      ws = new MozWebSocket(url);
    } else {
      console.log("您的浏览器不支持 WebSocket!");
      return;
    }

    ws.onmessage = (res) => {
      //为简单起见，要求每个res.data只包含一个完整的ctp包文,由JJY wss服务器保证
      let text = res.data;
      var index = text.indexOf("\r\n\r\n");
      var index2 = text.lastIndexOf("\r\n\r\n");
      if (index != index2) {
        // console.log("sock", "invalid single ctp packet:" + res.data);
      }
      //分为三种报文:request,notify,ack
      var packetType = "";
      let bundle = new Map();
      let items = text.split("\r\n");
      let cmd = "";
      for (var i = 0, len = items.length; i < len; i++) {
        var item = items[i];
        //debug.log("app",item);
        var pos = item.indexOf('=');
        if (pos != -1) {
          var name = item.substring(0, pos);
          var value = item.substring(pos + 1);
          if (name == "_cmd") {
            var posNoAck = value.indexOf(".noAck");
            var posAck = value.indexOf(".ack");
            if (posNoAck != -1) {
              packetType = "notify";
              cmd = value.substring(0, pos)
            } else if (posAck != -1) {
              packetType = "ack";
              cmd = value.substring(0, pos)
            } else {
              packetType = "request";
              cmd = value;
            }
          }
          bundle.set(name, value);
        }
      }
      if (packetType == "request") {
        websocket.onWebsocketRequest(cmd, bundle);
      } else if (packetType == "notify") {
        websocket.onWebsocketNotify(cmd, bundle);
      } else {
        websocket.onWebsocketAck(cmd, bundle);
      }
      //debug.log("app","json=" + bundle.get("json"))
      // heartCheck.start()
      // if (res.data == 'ok') { //心跳消息不做处理
      // 	return
      // }
      // messageHandle(e.data)
    }

    ws.onclose = () => {
      console.log("连接已关闭");
      wsConnected = false;
      this.fireEvent("unConnected", {});

      reconnect();
    }

    ws.onopen = () => {
      console.log("连接成功", ws.readyState);
      wsConnected = true;
      heartCheck.start();

      // console.log("fire connected********");
      this.fireEvent("connected", {});

      // if (window.location.pathname == "/") { //根路径：登录页面
      //   return;
      // }
      // // 页面一刷新就需重新登录
      // let loginMode = sessionStorage.getItem("loginMode");
      // let username = sessionStorage.getItem("username");
      // let password = sessionStorage.getItem("password");
      // let wxToken = sessionStorage.getItem("wxToken");
      // if ((loginMode == "pwd" && username == "") || (loginMode == "code" && wxToken == "")) {
      //   window.location.href = "/";
      //   return;
      // }

      // if (loginMode == "pwd") {
      //   websocket.send({
      //     "cmd": "userLogin",
      //     "user": username,
      //     "password": password
      //   });
      // } else {
      //   websocket.send({
      //     "cmd": "userLogin",
      //     "reconnectToken": wxToken
      //   })
      // }
    }

    ws.onerror = (res) => {
      console.log("数据传输发生错误");
      wsConnected = false;
      reconnect();
    }
  },

  send(json) {
    // if (ws == null || ws.readyState != 1) {
    // 	return;
    // }
    // let text = Base64.encode(JSON.stringify(json));
    // let data = "_cmd=json.noAck\r\njson=" + text + "\r\n\r\n";
    if (gDumpCommand) {
      console.log("yc.ws.send" + JSON.stringify(json));
    }
    // ws.send(data);
    let req = JSON.stringify(json);
    if (ws == null || ws.readyState != 1) {
      console.warn("ws=", ws, ",skip send " + req);
      return;
    }
    let text = Base64.encode(req);
    let data = "_cmd=json.noAck\r\njson=" + text + "\r\n\r\n";
    // console.log("send" + req);
    ws.send(data);
  },

  isConnected() {
    return wsConnected
  },
  wsClose() {
    ws.close()  //关闭连接
    clearTimeout(tt)  //清理定时器
    wsConnected = false;
    lockReconnect = false; //避免重复连接
  },
  //此方法没有清理监听
  addCallback(eventName, callback) {
    if (!events[eventName]) {
      events[eventName] = []
    }
    var item = {
      // object: obj,
      fn: callback
    }
    events[eventName].push(item)
    // console.log(events, eventName);
  },
  //这个方法可以清理监听
  addEventListener(eventName, obj, callback) {
    if (!events[eventName]) {
      events[eventName] = []
    }
    var item = {
      object: obj,
      fn: callback
    }
    events[eventName].push(item)
    // console.log("add_events", events);
  },

  removeCallback(eventName, obj) {
    var callbackItems = events[eventName]
    if (callbackItems && callbackItems.length) {
      //debug.log("app","callbackItems.length=" + callbackItems.length)
      for (var i = 0; i < callbackItems.length; i++) {
        var item = callbackItems[i]
        if (item.object == obj) {
          callbackItems.splice(i, 1);
          //Log.v(TAG,"remove match(" + eventName + "),callbackItems.length=" + callbackItems.length);
          return;
        } else {
          //debug.log("app","not match");
        }
      }
    }
  },
  removeAllEventListeners(obj) {
    Object.keys(events).forEach(function (key) {
      //debug.log("app","events=" + self.events.constructor.name)
      websocket.removeCallback(key, obj)
    });
    // console.log("remove_events", events);


  },
  onWebsocketRequest() { },

  onWebsocketNotify(cmd, bundle) {
    let json = bundle.get("json");
    let obj = JSON.parse(Base64.decode(json));

    if (gDumpCommand) {
      console.log("yc.ws.Recv", obj);
    }

    // pingAck
    if (obj.cmd == "pingAck") {
      isPing = true;
    }
    // 未登录
    if (obj.error == "ENeedLogin") {
      return;
    }
    this.fireEvent(obj.cmd, obj);
  },

  onWebsocketAck() { },

  fireEvent(eventName, jsonOrText) {
    let json = undefined
    if (jsonOrText != null && jsonOrText != undefined) {
      //debug.log("app","typeof (jsonOrText)=" + typeof (jsonOrText))
      if (typeof (jsonOrText) == "string") {
        if (jsonOrText != "") {
          json = JSON.parse(jsonOrText);
        }
      } else {
        json = jsonOrText
      }
    }

    if (json) {
      // console.log("recv:" + JSON.stringify(json));
    }

    let callbackItems = events[eventName]
    if (callbackItems && callbackItems.length) {
      for (let i = 0; i < callbackItems.length; i++) {
        let item = callbackItems[i]
        //item.object .(item.fn)()
        //debug.log("app","object:"+item.object)
        //debug.log("app","fn:" + item.fn)
        //https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Function/call
        //call() 方法使用一个指定的 this 值和单独给出的一个或多个参数来调用一个函数。

        item.fn.call(this, json)
      }
    }

    if (eventName == "userLoginAck") {
      this.fireEvent("jsonChannelReady", {});
    }
  }
}

export default websocket;

//根据消息标识做不同的处理
function messageHandle(message) {
  // console.log(message);
  // let msg = JSON.parse(message)
  // switch (msg.flag) {
  // 	case 'command':
  // 		console.log("指令消息类型")
  // 		break;
  // 	case 'inform':
  // 		console.log("通知")
  // 		break;
  // 	default:
  // 		console.log("未知消息类型")
  // }
}

function reconnect() {
  if (lockReconnect) {
    return;
  };
  lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  tt && clearTimeout(tt);
  tt = setTimeout(function () {
    console.log("执行断线重连...")
    websocket.connect();
    lockReconnect = false;
  }, 5000);
}

//心跳检测
var heartCheck = {
  // timeout: 1000 * 60 * 3,
  timeout: 1000 * 30,
  // timeout: 1000, //测试
  timeoutObj: null,
  serverTimeoutObj: null,
  start: function () {
    var self = this;
    this.timeoutObj && clearInterval(this.timeoutObj);
    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
    this.timeoutObj = setInterval(function () {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      //onmessage拿到返回的心跳就说明连接正常
      console.log('开始心跳检测...', ws.readyState, lockReconnect);
      if (ws != null && ws.readyState == 1) {
        let json = {
          cmd: "ping"
        }
        websocket.send(json);
        // startPingTimer();
        // console.log("发送心跳ready");
      }
      self.serverTimeoutObj = setTimeout(function () {
        if (ws.readyState != 1) {
          ws.close();
        }
        // createWebSocket();
      }, self.timeout);
    }, this.timeout)
  }
}

// 15秒后没有收到ping回复重连
let pingTimer = null;

function startPingTimer() {
  if (pingTimer) {
    clearTimeout(pingTimer);
    pingTimer = null;
  }
  pingTimer = setTimeout(() => {
    if (!isPing) {
      reconnect();
      clearTimeout(pingTimer);
      isPing = false;
    }
  }, 1000 * 15)
}