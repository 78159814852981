<template>
  <div>
    <!--今日雨量-->
    <LiveData class="liveData" :liveData="dayRainData"></LiveData>
    <!--月雨量-->
    <LiveData class="liveData" :liveData="monthRainData"></LiveData>
    <!--年雨量-->
    <LiveData class="liveData" :liveData="yearRainData"></LiveData>

  </div>

</template>

<script>
import LiveData from './liveItem.vue'

export default {
  components: {
    LiveData,
  },
  props: {
    liveData: {
      type: Object,
    }
  },
  data() {
    return {
      dayRain: '',
      monthRain: '',
      yearRain: ''
    }
  },

  computed: {
    dayRainData() {
      let data = {
        title: '今日雨量',
        num: this.liveData.dayRain,
        unit: 'mm'
      }
      return data
    },
    monthRainData() {
      let data = {
        title: '月雨量',
        num: this.liveData.monthRain,
        unit: 'mm'
      }

      return data
    },
    yearRainData() {
      let data = {
        title: '年雨量',
        num: this.liveData.yearRain,
        unit: 'mm'
      }
      return data
    }

  }

}
</script>

<style lang="scss">
.liveData {
  margin-bottom: 5px;
}
</style>