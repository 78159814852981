<template>
  <div class="live">
    <div class="live-title">{{liveData.title}}</div>
    <div class="live-content">
      <div class="data">{{liveData.num}}</div>
      <div class="unit">{{liveData.unit}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    liveData: {
      type: Object,
    }
  },
}
</script>

<style lang="scss">
$h: 30px;
$w: 85px;
.live {
  display: flex;
  .live-title {
    height: $h;
    width: $w;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 30px;
    background-color: #07162e;
    color: #ffffff;
    text-align: center;
    border-style: solid;
    border-color: #ffffff;
  }
  .live-content {
    height: $h;
    width: 90px;
    background-color: #ffffff;
    margin-left: 3px;
    border-style: solid;
    border-color: #ffffff;
    display: flex;
    align-items: center;
    .data {
      font-size: 16px;
      width: 56px;
      text-align: center;
    }
    .unit {
      color: #5e5d5e;
      background-color: #e3d9e1;
      height: 28px;
      width: 33px;
      line-height: 26px;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>>

