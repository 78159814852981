import ws from "../../../common/websocket"
import yc_ws from "../../../common/yichun_websocket"
let basepage = {
  methods: {
    //此方法可以实现自动清理监听
    on(eventName, callback) {
      yc_ws.addEventListener(eventName, this, callback)
    },
    send(json) {
      yc_ws.send(json)
    },
    setScreen() {
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      // console.log(3331, screenWidth, screenHeight);
      this.targetX = screenWidth
      this.targetY = screenHeight
      this.targetRatio = parseInt(screenWidth) / parseInt(screenHeight)
    },
    resizeFunc: () => {
      this.triggerScale();
    },
    triggerScale() {
      // console.log("执行了");
      // 设计稿尺寸
      let targetX = this.option.targetX;
      let targetY = this.option.targetY;
      let targetRatio = this.option.targetRatio;

      // 拿到当前设备的宽高
      let currentX = document.documentElement.clientWidth || document.body.clientWidth;
      let currentY = document.documentElement.clientHeight || document.body.clientHeight;

      let scaleRadio = void 0
      let currentRadio = currentX / currentY;
      // 根据设计稿尺寸和当前电脑尺寸比较
      if (currentRadio > targetRatio) {
        // 按高度缩放，居中展示
        scaleRadio = currentY / targetY;
        this.$refs.wrapper.style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio}) translateX(-50%);left:50%;`;
        // document.getElementById("my-pic-wrapper").
      } else {
        scaleRadio = currentX / targetX;
        this.$refs.wrapper.style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio});`;
        // document.getElementById("my-pic-wrapper").style = `width:${targetX}px;height:${targetY}px;transform:scale(${scaleRadio});`;
      }
    },
    debounce(func, delay = 1000, immediate = false) {
      let timer = null
      //不能用箭头函数
      return function () {
        //在时间内重复调用的时候需要清空之前的定时任务
        if (timer) {
          clearTimeout(timer)
        }
        //适用于首次需要立刻执行的
        if (immediate && !timer) {
          func.apply(this, arguments)
        }
        timer = setTimeout(() => {
          func.apply(this, arguments)
        }, delay)
      }
    },

  },
  created() {
    this.setScreen()
    //判断主ws是否处于运行状态
    if (ws.isConnected()) {
    }


    //启动宜春ws连接
    if (!yc_ws.isConnected()) {
      //未启动
      yc_ws.connect()
    }

  },
  mounted() {
    window.addEventListener("resize", this.debounce(this.triggerScale, 300, false));
    this.triggerScale()
  },
  destroyed() {
    //清除监听
    yc_ws.removeAllEventListeners(this)
    window.removeEventListener("resize", this.triggerScale);
    yc_ws.wsClose()
  },
}
export default basepage;